<template>
  <div class="college_CJ">
    <div class="container">
      <div v-loading="loading" class="Main">
        <div class="mb">
          <span>创建公共学堂</span>
        </div>
        <el-steps :active="active" align-center finish-status="success" class="step">
          <el-step title="学堂基本信息"></el-step>
          <el-step title="主体信息"></el-step>
          <el-step title="完成"></el-step>
        </el-steps>
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          label-width="280px"
          class="demo-ruleForm"
          :label-position="labelPosition"
        >
          <el-form-item label="学堂名称:" prop="Collname">
            <el-input
              v-model="ruleForm.Collname"
              placeholder="请输入学堂名称0~20个汉字"
              class="inputer"
            ></el-input>
          </el-form-item>
          <el-form-item label="预计发布课程数:" prop="num">
            <el-select v-model="ruleForm.num" placeholder="请选择" class="inputer">
              <!-- <el-option label="未知" value="0"></el-option> -->
              <el-option label="50以下" value="1"></el-option>
              <el-option label="50-90" value="2"></el-option>
              <el-option label="100-199" value="3"></el-option>
              <el-option label="200-499" value="4"></el-option>
              <el-option label="500-1000" value="5"></el-option>
              <el-option label="1000以上" value="6"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="学堂介绍:" prop="desc">
            <el-input
              v-model="ruleForm.desc"
              type="textarea"
              :rows="5"
              placeholder="请输入至少五个字符"
              class="desc"
            ></el-input>
          </el-form-item>
          <el-form-item label="学堂logo图标:" required>
            <el-upload
              class="avatar-uploader"
              action=""
              :limit="1"
              :file-list="fileList"
              :http-request="uploadRequest"
              list-type="picture-card"
              accept=".jpg,.jpeg,.png,.JPG,.JPEG"
              :on-remove="handleRemove"
            >
              <i class="el-icon-plus avatar-uploader-icon"></i>
              <div slot="tip" class="el-upload__tip">
                图片大小最好在200 X200，支持扩展名：.png .jpg .jpeg...
              </div>
            </el-upload>
          </el-form-item>
        </el-form>
        <div class="commitbtn">
          <a @click="gonext()">创建学堂</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { addorg, uploadimage } from '@/api/create'
export default {
  data() {
    return {
      loading: false,
      active: 1,
      fileList: [],
      imageurl: '',
      ruleForm: {
        Collname: this.$route.query.Collegename,
        num: '',
        desc: '',
      },
      labelPosition: 'right',
      rules: {
        Collname: [
          { required: true, message: '请输入学堂名称', trigger: 'blur' },
          { min: 0, max: 24, message: '长度不得大于24字符', trigger: 'blur' },
        ],
        num: [{ required: true, message: '请选择', trigger: 'change' }],
        desc: [
          { required: true, message: '请输入学堂简介', trigger: 'blur' },
          { min: 5, max: 200, message: '长度在 5 到 200 个字符', trigger: 'blur' },
        ],
      },
      organizationid: '', //成功后的企业id
    }
  },
  mounted() {
    const { name } = this.$route.query
    if (name) this.ruleForm.Collname = name
  },
  methods: {
    uploadRequest(file) {
      const params = new FormData() //创建FormData
      params.append('file', file.file)
      uploadimage(params).then(res => {
        if (res.msg == 'success') {
          this.imageurl = res.data.objectUrl
          this.fileList.push({ url: res.data.objectUrl })
        }
      })
    },
    handleRemove(file, fileList) {
      this.fileList = fileList
    },
    gonext() {
      this.$refs['ruleForm'].validate(async valid => {
        if (valid) {
          if (this.fileList == '') {
            this.$message.error('请选择学堂logo~')
            return
          }
          this.loading = true
          addorg({
            capacityRange: parseInt(this.ruleForm.num),
            introduction: this.ruleForm.desc,
            logo: this.imageurl,
            organizationName: this.ruleForm.Collname,
          }).then(res => {
            this.loading = false
            if (res.msg == 'success') {
              this.organizationid = res.data.organizationId
              this.$router.push({
                path: '/createMid',
                query: { organizationid: this.organizationid },
              })
            } else {
              this.$message({
                message: res.msg,
                type: 'warning',
              })
            }
          })
        }
      })
    },
  },
}
</script>
<style scoped>
.college_CJ {
  width: 100%;
  height: 100%;
  background: #f8f8f8;
}
.container {
  display: flex;
  justify-content: center;
}
.Main {
  /* margin-left: 500px; */
  width: 1200px;
  height: auto;
  margin-top: 20px;
  background: #ffffff;
}
.mb {
  width: 100%;
  border-bottom: 1px solid #e2e2e2;
}
.mb span {
  display: inline-block;
  font-size: 18px;
  font-family: Source Han Sans CN, Source Han Sans CN-Medium;
  font-weight: 500;
  text-align: left;
  color: #4d4e5d;
  margin: 20px 27px;
}
.step {
  margin-top: 55px;
}
.Main >>> .el-step__head.is-success {
  color: #ff7b33;
  border-color: #ff7b33;
}
.Main >>> .el-step__title.is-success {
  color: #ff7b33;
}
.Main >>> .el-step__head.is-process {
  color: #a1a3a8;
  border-color: #a1a3a8;
}
.Main >>> .el-step__title.is-process {
  font-weight: 700;
  color: #a1a3a8;
}
.demo-ruleForm {
  margin-top: 80px;
}
.inputer {
  width: 350px;
}
.desc {
  width: 600px;
  height: 118px;
}
.avatar-uploader >>> .el-upload {
  width: 120px;
  height: 120px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader >>> .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 138px;
  text-align: center;
  margin-top: -10px;
}
.avatar {
  width: 120px;
  height: 120px;
  display: block;
}
.el-upload__tip {
  line-height: 20px;
  margin-top: 10px;
}
.commitbtn {
  margin: 75px 0 0 280px;
}
.commitbtn a {
  display: inline-block;
  width: 218px;
  height: 40px;
  background: #ff7b33;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  line-height: 40px;
  margin-bottom: 50px;
  text-align: center;
  cursor: pointer;
}
</style>
